import { Module } from 'tabulator-tables';
import "./utils"
import { flash_message } from "./utils"

class LoadContextModule extends Module {
    // load context and group menus dynamicaly
    constructor(table) {
        super(table);
        this.registerTableOption("apiPoint", false);
        this.registerTableOption("baseID", false);
    }

    initialize() {
        if (this.options("apiPoint")) {
            this.subscribe("row-contextmenu", this.loadRowContextMenu.bind(this));
            this.subscribe("column-contextmenu", this.loadColumnContextMenu.bind(this));
        }
    }

    loadRowContextMenu(e, row) {
        e.stopPropagation();
        e.preventDefault();
        let pk = row.getData().id
        let url = this.options("apiPoint") + pk + "/contextmenu/"
        fetch(url)
            .then(function(responce) {
                return responce.json();
            })
            .then(function(data) {
                let menu_data = []
                data.forEach(item => {
                    if (item) { menu_data.push(uiToMenu(item, row.table)) }
                });
                row.table.modules.menu.loadMenu(e, row, menu_data);
            })
    }

    loadColumnContextMenu(e, column) {
        e.stopPropagation();
        e.preventDefault();
        let menu_data = [{
                label: "<i class='fa-solid fa-square-check'></i>&nbsp;" + gettext("Select all"),
                action: function(e, row) {
                    column.table.selectRow();
                }
            },
            {
                label: "<i class='fa-solid fa-square'></i>&nbsp;" + gettext("Unselect all"),
                action: function(e, row) {
                    column.table.deselectRow();
                }
            },
        ]
        let tableSetupMenu = this.getTableSettingsMenu();
        let selected_data = this.table.getSelectedData();
        if (selected_data.length > 0) {
            let query = new URLSearchParams();
            query.append("field", column.definition.field);
            selected_data.forEach(item => { query.append("item", item.id) });
            let url = this.options("apiPoint") + "groupmenu/?" + query.toString();
            fetch(url)
                .then(function(responce) {
                    return responce.json();
                })
                .then(function(data) {
                    menu_data.push({ separator: true, });
                    data.forEach(item => {
                        if (item) { menu_data.push(uiToMenu(item, column.table)); }
                    });
                    menu_data.push(tableSetupMenu);
                    column.table.modules.menu.loadMenu(e, column, menu_data);
                })
        } else {
            menu_data.push(tableSetupMenu);
            column.table.modules.menu.loadMenu(e, column, menu_data);
        }
    }


    getTableSettingsMenu() {
        // get table settings menu
        let menu_data = {
            label: "<i class='fa-solid fa-gear'></i> Table settings",
            menu: [{
                    label: "<i class='fa-solid fa-ban'></i>&nbsp;Hide column",
                    action: function(e, column) {
                        column.hide();
                        column.getTable().showColumn("name");
                    }
                },
                { separator: true, },
                {
                    label: "<i class='fa-solid fa-eye'></i>&nbsp;Show all columns",
                    action: function(e, column) {
                        column.getTable().getColumns().forEach(col => { col.show() });
                        column.getTable().hideColumn("id");
                    }
                },
                {
                    label: "<i class='fa-solid fa-calendar-days'></i>&nbsp;Toggle dates",
                    action: function(e, column) {
                        column.getTable().toggleColumn("start");
                        column.getTable().toggleColumn("finish");
                    }
                },
            ]
        }
        return menu_data;
    }
}
LoadContextModule.moduleName = 'LoadContextModule'
LoadContextModule.moduleInitOrder = 10


function uiToMenu(item, table = false) {
    if (!item) { return "" }
    switch (item.type) {
        case "action":
            return {
                label: "<i class='" + item.icon + "'></i> " + item.text,
                action: function(e, column) {
                    let query = new URLSearchParams();
                    if (table ^ item.value == "selected") {
                        table.getSelectedData().forEach(item => { query.append("item", item.id) })
                    }
                    query.append("next", window.location.href)
                    window.location.href = item.link + "?" + query.toString();
                }
            };

        case "transition":
            // ask for transition and return updated row
            return {
                label: "<i class='" + item.icon + "'></i> " + item.text,
                action: function(e, row) {
                    link = item.link + row.getData().pk + "/transition/"
                    fetch(link, {
                            method: 'POST',
                            body: JSON.stringify({ "trasition": item.value }),
                        })
                        .then(function(responce) { return responce.json(); })
                        .then(function(data) { row.update(data); })
                        .catch((error) => {
                            console.error('Error:', error);
                        });
                }
            };

        case "link":
            return {
                label: "<i class='" + item.icon + "'></i> " + item.text,
                action: function(e, column) {
                    window.location.href = addNextURL(item.link);
                }
            };

        case "submit":
            return {
                label: "<i class='" + item.icon + "'></i> " + item.text,
                action: function(e, column) {
                    let csrftoken = document.querySelector('input[name="csrfmiddlewaretoken"]').value

                    fetch(addNextURL(item.link), {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            "X-Requested-With": "XMLHttpRequest",
                            "X-CSRFToken": csrftoken,
                        },
                        body: JSON.stringify({ "transition": item.value })
                    }).then(res => {
                        window.location.reload(true);
                        //if (res.success) { column.getTable().replaceData(); }
                        return res.success;
                    });
                }
            };

        case "devider":
            return { separator: true, };
    }
}

function addNextURL(link) {
    // add next tag to query param
    let updated_link = new URL(link, window.location.origin)
    updated_link.searchParams.set('next', window.location.href);
    return updated_link
}

function parse_ui(ui_list) {
    // parse list of UIitem dicts and return Tabulator menu list
    let out_html = [];
    ui_list.forEach(element => {
        if (element.type == "link") {
            out_html.push({
                label: "<i class='" + element.icon + "'></i> " + element.text,
                action: function(e, column) { window.location.href = element.link; }
            });
        } else if (element.type == "submit") {
            out_html.push({
                label: "<i class='" + element.icon + "'></i> " + element.text,
                action: function(e, column) {
                    let csrftoken = document.querySelector('input[name="csrfmiddlewaretoken"]').value
                    fetch(element.link, {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            "X-Requested-With": "XMLHttpRequest",
                            "X-CSRFToken": csrftoken,
                        },
                        body: JSON.stringify({ "transition": element.value })
                    }).then(res => {
                        window.location.reload(true);
                        //if (res.success) { column.getTable().replaceData(); }
                        return res.success;
                    });
                }
            });
        } else if (element.type == "devider") { out_html.push({ separator: true, }); };
    });
    return out_html;
};


function dateIcon(data) {
    // return icon Dom element
    let today = new Date().toISOString();
    let icon = document.createElement("i");
    icon.style = "color:black";

    if (data.end_actual) {
        icon.classList = "fa-solid fa-flag-checkered";
        icon.title = gettext("Mark as finished");
    } else if (data.begin_actual) {
        icon.classList = "far fa-play-circle";
        icon.title = gettext("On going")
        if (data.end_planned > today) {
            icon.style = "color:red";
            icon.title = gettext("Planned finish is in the past");
        }
    } else {
        const IT_COULD_BE_INFINITY = "2100-12-12";
        let infinity = new Date(IT_COULD_BE_INFINITY);
        if (data.begin_planned > infinity) {
            icon.classList = "fas fa-infinity";
            icon.style = "color:blue";
            icon.title = gettext("Planned date is not set");
        } else {
            icon.classList = "far fa-clock";
            icon.title = gettext("Planned")
            if (today > data.begin_planned) {
                icon.style = "color:red";
                icon.title = gettext("Planned date is in the past");
            }
        }
    }
    return icon.outerHTML;
};

// https://gist.github.com/odewahn/5a5eeb23279eed6a80d7798fdb47fe91
function handleResponse(response) {
    // обрабатывет ошибки если есть или возвращает объект JSON
    return response.json()
        .then((json) => {
            if (!response.ok) {
                const error = Object.assign({}, {
                    messages: json,
                    status: response.status,
                    statusText: response.statusText,
                });
                for (var key in error.messages) {
                    let msg_list = typeof(error.messages[key]) === "string" ? [error.messages[key]] : error.messages[key];
                    msg_list.forEach(msg => { flash_message(msg, "error") });
                }
                console.log(error);

                return Promise.reject(error);
            }
            return json;
        });
}

function updateData(cell, value) {
    return patchData(cell.getTable(), cell.getData().id, cell.getColumn().getField(), value);
}

function patchData(table, id, field_name, value) {
    // create api request to update one field
    var csrftoken = document.querySelector('input[name="csrfmiddlewaretoken"]').value;
    var send_data = {};
    send_data[field_name] = value;
    let url = table.options.apiPoint + id + "/";
    return fetch(url, {
            headers: {
                "X-CSRFToken": csrftoken,
                Accept: 'application/json',
                "Content-Type": "application/json;",
            },
            method: 'PATCH',
            body: JSON.stringify(send_data)
        })
        .then(handleResponse);
}

//Create Date Editor
// http://tabulator.info/docs/5.2/edit#main-contents
var dateEditor = function(cell, onRendered, success, cancel) {
    //cell - the cell component for the editable cell
    //onRendered - function to call when the editor has been rendered
    //success - function to call to pass thesuccessfully updated value to Tabulator
    //cancel - function to call to abort the edit and return to a normal cell

    //create and style input
    var cellValue = cell.getValue();
    var input = document.createElement("input");
    input.setAttribute("type", "date");
    input.style.padding = "4px";
    input.style.width = "100%";
    input.style.boxSizing = "border-box";
    input.value = cellValue;

    onRendered(function() {
        input.focus();
        input.style.height = "100%";
    });

    function onChange() {
        if (input.value != cellValue) {
            // call ajax
            updateData(cell, input.value)
                .then(json => { success(input.value); })
                .catch(error => { cancel(); });
        } else { cancel() }
    }

    //submit new value on blur or change
    input.addEventListener("blur", onChange);

    //submit new value on enter
    input.addEventListener("keydown", function(e) {
        if (e.keyCode == 13) {
            onChange();
        }

        if (e.keyCode == 27) {
            cancel();
        }
    });

    return input;
};


const si_statuses = [
    { code: 0, label: "?", icon: "??" },
    { code: 1, label: gettext("Idle"), icon: "<i class='fa-solid fa-pause'></i>" },
    { code: 10, label: gettext("Booked"), icon: "<i class='fa-solid fa-cart-shopping'></i>" },
    { code: 20, label: gettext("Collected"), icon: "<i class='fa-solid fa-cart-flatbed-suitcase'></i>" },
    { code: 30, label: gettext("Sent"), icon: "<i class='fa-solid fa-plane-departure'></i>" },
    { code: 34, label: gettext("On site"), icon: "<i class='fa-solid fa-location-dot'></i>" },
    { code: 36, label: gettext("On site broken"), icon: "<i class='fa-solid fa-location-pin'></i>" },
    { code: 38, label: gettext("Sent back from site"), icon: "<i class='fa-solid fa-truck-arrow-right'></i>" },
    { code: 40, label: gettext("Received"), icon: "<i class='fa-solid fa-plane-arrival'></i>" },
    { code: 50, label: gettext("Returned"), icon: "<i class='fa-solid fa-flag-checkered'></i>" },
    { code: 60, label: gettext("Damaged"), icon: "<i class='fa-solid fa-heart-crack'></i>" },
    { code: 70, label: gettext("Deducted"), icon: "<i class='fa-solid fa-xmark'></i>" },
]

/**
 * Возвращает объект с названием и иконкой статуса компонента резервирвоания
 * @param {int} status_code код статуса компонента резервирования
 */
function get_si_status(status_code) {
    let x = si_statuses.find(el => el.code === status_code);
    return (typeof x === 'undefined') ? si_statuses[0] : x;
}


export { LoadContextModule, parse_ui, dateEditor, dateIcon, patchData, handleResponse, get_si_status };
