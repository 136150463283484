import Cookies from 'js-cookie';

export function flash_message(text, level) {
    // use django approach to show flash messages
    const messages = document.getElementById("messages");
    const msg = document.createElement("div");
    msg.setAttribute('class', 'alert alert-' + level + ' alert-dismissible fade show');
    msg.setAttribute('role', 'alert');
    msg.appendChild(document.createTextNode(text));
    const btn = document.createElement("button");
    btn.setAttribute('type', 'button');
    btn.setAttribute('class', 'btn-close');
    btn.setAttribute('data-bs-dismiss', 'alert');
    btn.setAttribute('aria-label', 'Close');
    msg.appendChild(btn);
    messages.appendChild(msg);
    setTimeout(function() { msg.remove() }, 10000);
}

export async function send_table_action_ajax(url, table, data) {
    var csrftoken = document.querySelector('input[name="csrfmiddlewaretoken"]').value
    if (!csrftoken) { return false; }
    fetch(url, {
            method: "POST",
            headers: { "X-CSRFToken": csrftoken, "Content-Type": "application/json; charset=utf-8" },
            body: JSON.stringify(data),
        })
        .then(responce => responce.json())
        .then(result => {
            if (!result.success) {
                flash_message(result.message, 'error');
                table.replaceData();
            }
            return result.success;
        })
        .catch(error => {
            flash_message("Server responce error. Please update page", 'error');
            return false;
        });
}

/**
 * https://stackoverflow.com/questions/133925/javascript-post-request-like-a-form-submit
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */
export function post(path, params, method = 'post') {

    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    let parameters = Object.assign(params, { csrfmiddlewaretoken: document.querySelector('input[name="csrfmiddlewaretoken"]') })
    for (const key in parameters) {
        if (parameters.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = parameters[key];

            form.appendChild(hiddenField);
        }
    }
    document.body.appendChild(form);
    form.submit();
}

export function checkCookies() {
    let cookieNote = document.getElementById('cookie_note');
    let cookieBtnAccept = cookieNote.querySelector('.cookie_accept');

    // Если куки cookies_policy нет или она просрочена, то показываем уведомление
    if (!Cookies.get('cookies_policy')) {
        cookieNote.classList.add('show');
    }

    // При клике на кнопку устанавливаем куку cookies_policy на один год
    cookieBtnAccept.addEventListener('click', function() {
        Cookies.set('cookies_policy', 'true', { expires: 365 });
        cookieNote.classList.remove('show');
    });
}
